import React from 'react';
import Layout from "../layouts/pl";
import { useStaticQuery, graphql } from "gatsby";
import Fade from 'react-reveal/Fade';
import { FaCheck } from 'react-icons/fa';

const Cennik = (props) => {
  const data = useStaticQuery(graphql` 
  query {
    desktopImage: file(relativePath: { eq: "images/photos/las1.jpg" }) {
          childImageSharp {
              fluid(quality: 100){
              ...GatsbyImageSharpFluid
              }
          }   
      }
      mobileImage: file(relativePath: { eq: "images/photos/las1-mobile.jpg" }) {
        childImageSharp {
            fluid(quality: 50){
            ...GatsbyImageSharpFluid
            }
        }   
    }
  }`);

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Layout location={props.location}>
      <Fade up>
        <section id="section6">
          <div className="content-container prices">
          <h1>CENNIK</h1>

            <table style={{ marginBottom: 50 }}>
              <tr>
                <th></th>
                <th>Amber 1</th>
                <th>Amber 2</th>
                <th>Amber 3</th>
              </tr>
              <tr>
                <td><b>SEZON NISKI</b></td>
              </tr>
              <tr>
                <td>1.10 - 30.05</td>
                <td>200 zł - 220 zł</td>
                <td>180 zł - 200 zł</td>
                <td>250 zł - 300 zł</td>
              </tr>
              <tr>
                <td>1-30.06 i 1-30.09</td>
                <td>250 zł - 300 zł</td>
                <td>250 zł - 280 zł</td>
                <td>350 zł - 450 zł</td>
              </tr>
              <tr>
                <td><b>SEZON WYSOKI</b></td>
              </tr>
              <tr>
                <td>1.07-30.08</td>
                <td>400 zł - 500 zł</td>
                <td>350 zł - 450 zł</td>
                <td>600 zł - 800 zł</td>
              </tr>
              <tr>
                <td>Majówka, Wielkanoc, Boże Narodzenie, Sylwester</td>
                <td>280 zł - 450 zł</td>
                <td>250 zł - 400 zł</td>
                <td>350 zł - 600 zł</td>
              </tr>
            </table>

            <span className='divider'/>

            <h2>Dodatkowe informacje i warunki rezerwacji:</h2>
            <ul style={{ marginBottom: 50 }}>
              <li><FaCheck />Godzina przyjazdu od 16.00; Godzina wyjazdu do 10.00</li>
              <li><FaCheck />Do wyżej wymienionych cen należy doliczyć jednorazową opłatę za sprzątanie:
                <ul>
                  <li><FaCheck />Amber 1 i Amber 2 – 200 zł</li>
                  <li><FaCheck />Amber 3 - 250 zł</li>
                </ul>
              </li>

              <li><FaCheck />Obowiązuje  opłata klimatyczna 3 zł/osoba/doba </li>
              <li><FaCheck />W momencie zameldowania pobieramy gotówką kaucję zwrotną w wysokości:
              <ul>
                  <li><FaCheck />Amber 1 i Amber 2 – 300 zł</li>
                  <li><FaCheck />Amber 3 - 500 zł</li>
                </ul>
              </li>
              <li><FaCheck />Warunkiem gwarancji rezerwacji jest wpłata zaliczki:
              <ul>
                  <li><FaCheck />w ciągu 3 dni od potwierdzenia dostępności terminu  -30 %  wartości pobytu  na podstawie faktury proforma lub bezpośrednio na podane poniżej konto bankowe:</li>
                  <li><FaCheck />nr konta : 45102039580000970202595452 Bank PKO BP SA</li>
                </ul>
              </li>
              <li><FaCheck />pozostała wartość należności za pobyt płatna gotówką w momencie meldunku lub przelewem na podany wyżej nr konta dzień przed przyjazdem.</li>
              <li><FaCheck />W sezonie wysokim pobyt min. 7 dni</li>
              <li><FaCheck />W sezonie niskim pobyt min. 2 dni.</li>
            </ul>

            <span className='divider'/>

          </div>
        </section>
      </Fade>
    </Layout>
  )
}

export default Cennik
